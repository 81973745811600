<template>
  <v-card elevation="2" min-height="438">
    <!--    <v-toolbar dense>-->
    <!--      <v-toolbar-title>-->
    <!--        {{ title }}-->
    <!--      </v-toolbar-title>-->
    <!--    </v-toolbar>-->
    <v-card-title>{{ title }} ({{ data.length }})</v-card-title>
    <v-data-table :headers="headers" :items="data" :header-props="{ sortIcon: null }" :items-per-page="5"
                  mobile-breakpoint="100">
      <template v-slot:item.view="{item}">
        <v-btn @click="$router.push({path:`/order/${item.id}`})" icon>
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.order_item="{item}">
        <p class="text-truncate" style="margin-bottom: 0">
          {{ showProduct(item.order_item) }}
        </p>
      </template>
      <template v-slot:item.order_status="{ item }">
        <v-btn
            :color="colorStatus(item.order_status)"
            outlined
            raised
            small
            block
        >{{ item.order_status }}
        </v-btn>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    },
    data: {
      type: Array,
      default: () => ([])
    },
  },
  name: "OrderMiniCard",
  data: () => ({
    headers: [
      {text: '', value: 'view', width: 60, align: 'center'},
      {text: 'เลขสั่งซื้อ', value: 'id_display', width: 80, align: 'center'},
      {text: 'ชื่อลูกค้า', value: 'customer_name', align: 'start', minWidth: 150,},
      {text: 'สินค้า', value: 'order_item', width: 150},
      {text: 'ราคา', value: 'total_price', align: 'center'},
      {text: 'วันที่สั่ง', value: 'order_date', align: 'center',},
      {text: 'สถานะ', value: 'order_status', align: 'center', width: 100},
      // {text: 'ชำระเงิน', value: 'payment_status', align: 'center', width: 100},
    ]
  }),
  methods: {
    showProduct(item) {
      let string = ''
      item.forEach((i, index) => {
        if (i.name !== 'ค่าส่ง') {
          string += (`${i.name} ${i.quantity} ${i.unit}${index !== item.length - 1 ? ', ' : ''}`)
        }
      })
      return string
    },
    colorStatus(status) {
      switch (status) {
        case 'รับออเดอร์':
          return 'primary'
        case 'ใบเสนอราคา':
          return 'orange'
        case 'ดำเนินการ':
          return 'amber'
        case 'สำเร็จ':
          return 'green'
        case 'ชำระแล้ว':
          return 'green'
        case 'ยังไม่ชำระ':
          return 'error'
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        หน้าแรก
      </v-toolbar-title>
    </v-toolbar>
    <div class="mx-4 pb-0 box box-2 example" style="min-height: 100%;overflow: auto">
      <!--      <v-card>-->
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
          <v-card elevation="2" min-height="438">
            <v-card-title>ออเดอร์ที่ครบกำหนดชำระในอีก 3 วัน ({{ due3Date.length }})</v-card-title>
            <v-data-table :headers="headers" :items="due3Date" :header-props="{ sortIcon: null }" :items-per-page="5"
                          mobile-breakpoint="100">
              <template v-slot:item.view="{item}">
                <v-btn @click="$router.push({path:`/order/${item.id}`})" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.order_status="{ item }">
                <v-btn
                    :color="colorStatus(item.order_status)"
                    outlined
                    raised
                    small
                    block
                >{{ item.order_status }}
                </v-btn>
              </template>

              <template v-slot:item.payment_status="{ item }">
                <v-btn
                    :color="colorStatus(item.payment_status)"
                    outlined
                    raised
                    small
                    block
                >{{ item.payment_status }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <OrderMiniCard title="ใบเสนอราคา" :data="quotationList"/>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <OrderMiniCard title="รับออเดอร์" :data="operationList"/>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <OrderMiniCard title="ดำเนินการ" :data="takeOrderList"/>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <OrderMiniCard title="ค้างชำระ" :data="notPaidList"/>
        </v-col>
      </v-row>
      <!--      </v-card>-->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DateTime from "luxon/src/datetime";
import OrderMiniCard from "@/components/OrderMiniCard";

export default {
  name: "Home",
  components: {OrderMiniCard},
  data: () => ({
    orders: [],
    quotationList: [],
    operationList: [],
    takeOrderList: [],
    notPaidList: [],
    due3Date: [],
    headers: [
      {text: '', value: 'view', width: 60, align: 'center'},
      {text: 'เลขสั่งซื้อ', value: 'id_display', width: 80, align: 'center'},
      {text: 'ชื่อลูกค้า', value: 'customer_name', align: 'start', minWidth: 150,},
      {text: 'ราคา', value: 'total_price', align: 'center'},
      {text: 'วันครบกำหนด', value: 'due_date', align: 'center',},
      {text: 'สถานะ', value: 'order_status', align: 'center', width: 100},
      {text: 'ชำระเงิน', value: 'payment_status', align: 'center', width: 100},
    ]
  }),
  async created() {

    let dates = [DateTime.now().minus({months: 1}).toFormat('yyyy/LL/dd'), DateTime.now().toFormat('yyyy/LL/dd')]
    axios.get(`/api/orders/?start_date=${dates[0]}&end_date=${dates[1]}`)
        .then(res => {
          this.quotationList = res.data.data.filter(o => o.order_status === 'ใบเสนอราคา')
          this.operationList = res.data.data.filter(o => o.order_status === 'รับออเดอร์')
          this.takeOrderList = res.data.data.filter(o => o.order_status === 'ดำเนินการ')
          this.notPaidList = res.data.data.filter(o => o.order_status === 'สำเร็จ' && o.payment_status === 'ยังไม่ชำระ')
        })
    axios.get('/api/tracks/due_date/')
        .then(res => {
          console.log(res.data.data)
          this.due3Date = res.data.data
        }).catch(e => {
      if (e.response.status === 401) {
        this.$router.push({path: '/login'})
      }
      this.$store.state.snackbar = {
        text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
        visible: true,
        color: 'error'
      }
    })

  },
  methods: {
    colorStatus(status) {
      switch (status) {
        case 'รับออเดอร์':
          return 'primary'
        case 'ใบเสนอราคา':
          return 'orange'
        case 'ดำเนินการ':
          return 'amber'
        case 'สำเร็จ':
          return 'green'
        case 'ชำระแล้ว':
          return 'green'
        case 'ยังไม่ชำระ':
          return 'error'
      }
    },
    // getDiff(date) {
    //   let now = DateTime.now().toISOString()
    //   let dueDate = DateTime.fromFormat('yyyy/LL/dd', date).toISOString()
    //   const diff = dueDate.diff(now, ["years", "months", "days", "hours"])
    //   console.log(diff)
    //   return diff
    // }
  }
}
</script>

<style scoped>
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>